import browser from 'browser-detect';

export default {
    browser: browser(),

    suggest: '',

    isOk () {
        this.testBrowser()

        return this.suggest === '';
    },

    testBrowser () {
        if (this.browser.mobile) {
            return this.testBrowserMobile()
        }

        return this.testBrowserDesktop();
    },

    testBrowserMobile () {
        let os = this.browser.os.toLowerCase(),
            br = this.browser.name.toLowerCase();

        if (os.includes('os x') && br !== 'safari') {
            this.suggest = 'For a better user experience, please use Safari on iOS devices';
            return;
        } else if (os.includes('android') && br !== 'chrome') {
            this.suggest = 'For a better user experience, please use Chrome on Android devices';
            return;
        }

        this.suggest = '';
    },

    testBrowserDesktop () {
        let os = this.browser.os.toLowerCase(),
            br = this.browser.name.toLowerCase();

        if (os.includes('os x') && (br !== 'safari' && br !== 'chrome')) {
            this.suggest = 'For a better user experience, please use Safari on OSX';
            return;
        } else if (os.includes('linux') && br !== 'chrome') {
            this.suggest = 'For a better user experience, please use Chrome on Linux';
            return;
        } else if (os.includes('windows') && br !== 'chrome' && br !== 'edge') {
            this.suggest = 'For a better user experience, please use Chrome or Edge on Windows';
            return;
        }

        this.suggest = '';
    }
}
